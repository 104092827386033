import React, { useState, useEffect, useRef } from 'react';
import { splatterFetch } from './helpers';
import { 
  Container, 
  Typography, 
  Slider, 
  Button, 
  Paper, 
  Grid, 
  Box, 
  createTheme,
  useMediaQuery,
  Alert,
  DialogContent,
  DialogActions,
  Dialog,
  DialogTitle,
  IconButton,
  CircularProgress,
  styled,
  ThemeProvider
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const PRIMARY_MAIN = "#C075D1"

const theme = createTheme({
  palette: {
    primary: {
      main: PRIMARY_MAIN, // Deep Sea Green
    },
    secondary: {
      main: '#D4A373', // Warm sand color
    },
    background: {
      default: '#F5F5F5',
    },
  },
  typography: {
    h1: {
      fontWeight: 700,
    },
    h2: {
      fontWeight: 600,
    },
    h3: {
      fontWeight: 600,
    },
    h4: {
      fontWeight: 600,
    },
    h5: {
      fontWeight: 500,
    },
    h6: {
      fontWeight: 500,
    },
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '0.875rem',
    },
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: '12px',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '1.5rem',
          fontWeight: 600,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '16px 24px',
        },
      },
    },
  },
});

/**
 * most of this file is generated by claude
 */
interface SliderValues {
  red_percentage: number;
  yellow_percentage: number;
  blue_percentage: number;
}

interface Turn {
  red_bucket_delta: number;
  blue_bucket_delta: number;
  yellow_bucket_delta: number;
  red_percentage: number;
  blue_percentage: number;
  yellow_percentage: number;
  color: string;
}

const getCorrectSliderColor = (color: string) => {
  switch (color) {
    case 'red':
      return '#ff6961'; 
    case 'yellow':
      return '#ffd700';
    case 'blue':
      return '#4169e1'; 
    default:
      return 'inherit';
  }
};

interface ColorSliderProps {
  color: 'red' | 'yellow' | 'blue';
  value: number;
  onChange: (value: number) => void;
  disabled: boolean;
  isCorrect: boolean;
}

const ColorSlider: React.FC<ColorSliderProps> = ({ color, value, onChange, disabled, isCorrect }) => {
  const getSliderColor = () => {
    if (isCorrect) {
      return getCorrectSliderColor(color);
    }
    return '#9e9e9e'; // grey color when not correct
  };

  const sliderColor = getSliderColor();

  const handleChange = (_: Event, newValue: number | number[]) => {
    onChange(newValue as number);
  };

  const handleIncrement = (amount: number) => {
    const newValue = Math.min(Math.max(value + amount, 0), 100);
    onChange(newValue);
  };


  return (
    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', mb: 1 }}>
      <Typography variant="subtitle2" sx={{ 
        fontFamily: "'Bangers', cursive", 
        color: getCorrectSliderColor(color), 
        width: '20px',
        mr: 1,
        fontSize: '0.8rem'
      }}>
        {color.charAt(0).toUpperCase()}
      </Typography>
      <Slider
        value={value}
        onChange={handleChange}
        disabled={disabled || isCorrect}
        sx={{
          flexGrow: 1,
          marginRight: 1,
          color: sliderColor,
          '& .MuiSlider-thumb': {
            backgroundColor: sliderColor,
            width: 16,
            height: 16,
          },
          '& .MuiSlider-track': {
            backgroundColor: sliderColor,
            height: 4,
          },
          '& .MuiSlider-rail': {
            backgroundColor: `${sliderColor}40`,
            height: 4,
          },
        }}
      />
      <Box sx={{ display: 'flex', width: '50px' }}>
        <Button onClick={() => handleIncrement(-1)} disabled={disabled || isCorrect} sx={{ minWidth: '24px', p: 0.5 }}>-</Button>
        <Button onClick={() => handleIncrement(1)} disabled={disabled || isCorrect} sx={{ minWidth: '24px', p: 0.5 }}>+</Button>
      </Box>
    </Box>
  );
};

interface ComparisonColorSliderProps {
  color: 'red' | 'yellow' | 'blue';
  userValue: number;
  correctValue: number;
}

const SliderWrapper = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '8px',
});

const SliderTrack = styled(Box)({
  width: '100%',
  height: '4px',
  backgroundColor: '#e0e0e0',
  position: 'relative',
});

const SliderFill = styled(Box)<{ $color: string }>(({ $color }) => ({
  height: '100%',
  backgroundColor: $color,
  position: 'absolute',
}));

const SliderThumb = styled(Box)<{ $color: string }>(({ $color }) => ({
  width: '12px',
  height: '12px',
  borderRadius: '50%',
  backgroundColor: $color,
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
}));

const ComparisonColorSlider: React.FC<ComparisonColorSliderProps> = ({ color, userValue, correctValue }) => {
  const getSliderColor = (isCorrect: boolean) => {
    const baseColor = color === 'red' ? '#ff6961' : color === 'yellow' ? '#ffd700' : '#4169e1';
    return isCorrect ? baseColor : `${baseColor}80`;
  };

  const userColor = getSliderColor(userValue === correctValue);
  const correctColor = getSliderColor(true);

  const safeUserValue = Math.max(0, Math.min(100, Number(userValue) || 0));
  const safeCorrectValue = Math.max(0, Math.min(100, Number(correctValue) || 0));

  const startPosition = Math.min(safeUserValue, safeCorrectValue);
  const endPosition = Math.max(safeUserValue, safeCorrectValue);

  return (
    <SliderWrapper>
      <Typography variant="caption" sx={{ 
        fontFamily: "'Bangers', cursive", 
        color: correctColor,
        width: '15px',
        marginRight: '8px',
        fontSize: '0.8rem'
      }}>
        {color.charAt(0).toUpperCase()}
      </Typography>
      <SliderTrack>
        <SliderFill 
          $color={userColor} 
          style={{ 
            left: `${startPosition}%`, 
            width: `${endPosition - startPosition}%` 
          }} 
        />
        <SliderThumb $color={userColor} style={{ left: `${safeUserValue}%` }} />
        <SliderThumb $color={correctColor} style={{ left: `${safeCorrectValue}%` }} />
      </SliderTrack>
    </SliderWrapper>
  );
};

const getEmoji = (color: string, delta: number, turn_number: number) => {
  if (delta === 0) {
    switch (color) {
      case 'red':
        return '🟥';
      case 'yellow':
        return '🟨';
      case 'blue':
        return '🟦';
      default:
        return '⬜';
    }
  }
  if (delta === -1 || delta === 1) {
    switch (color) {
      case 'red':
        return '🔴';
      case 'yellow':
        return '🟡';
      case 'blue':
        return '🔵';
      default:
        return '⬜';
    }
  }

  if (turn_number < 3) {
    return '⬜'
  }


  return delta < 0 ? '⬆️' : '⬇️';
};

const TurnList: React.FC<{ turns: Turn[] }> = ({ turns }) => {
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5, borderBottom: '1px solid rgba(0, 0, 0, 0.12)' }}>
        <Typography variant="caption" sx={{ width: '15%', fontWeight: 'bold', fontSize: isXs ? '0.65rem' : '0.8rem' }}>
          Turn
        </Typography>
        <Typography variant="caption" sx={{ width: '85%', display: 'flex', justifyContent: 'space-around', fontWeight: 'bold', fontSize: isXs ? '0.65rem' : '0.8rem' }}>
          <span>🟥</span>
          <span>🟨</span>
          <span>🟦</span>
        </Typography>
      </Box>
      {turns.map((turn, index) => (
        <Box key={turns.length - index} sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.25 }}>
          <Typography variant="caption" sx={{ width: '15%', fontSize: isXs ? '0.6rem' : '0.75rem' }}>
            {turns.length - index}
          </Typography>
          <Typography variant="caption" sx={{ width: '85%', display: 'flex', justifyContent: 'space-around', fontSize: isXs ? '0.7rem' : '0.85rem' }}>
            <span>{getEmoji('red', turn.red_bucket_delta, turns.length - index)}</span>
            <span>{getEmoji('yellow', turn.yellow_bucket_delta, turns.length - index)}</span>
            <span>{getEmoji('blue', turn.blue_bucket_delta, turns.length - index)}</span>
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

function App() {
  const [targetColor, setTargetColor] = useState<string>("#ffffff");
  const [lastUserColor, setLastUserColor] = useState<string>("#ffffff");
  const [sliderValues, setSliderValues] = useState<SliderValues>({
    red_percentage: 0,
    yellow_percentage: 0,
    blue_percentage: 0,
  });
  const [correctSliderValues, setCorrectSliderValues] = useState<SliderValues>({
    red_percentage: 0,
    yellow_percentage: 0,
    blue_percentage: 0,
  });

  // the booleans below refer to if the color is correct
  const [correctColors, setCorrectColors] = useState<{
    red: boolean;
    yellow: boolean;
    blue: boolean;
  }>({
    red: false,
    yellow: false,
    blue: false,
  });
  const [gamePlayId, setGamePlayId] = useState<string>();
  const [gamePlayScore, setGamePlayScore] = useState<number>();
  const [turns, setTurns] = useState<Array<Turn>>([]);
  const [message, setMessage] = useState<string>('');
  const [readableDate, setReadableDate] = useState<string>();
  const [readableRef, setReadableRef] = useState<string>();
  const [showInstructionsPopup, setShowInstructionsPopup] = useState<boolean>(false)
  const [showEndGamePopup, setShowEndGamePopup] = useState<boolean>(false);
  const [gameCompleteTitle, setGameCompleteTitle] = useState<string>("Splatter!");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showScoringExplanationDialog, setShowScoringExplanationDialog] = useState<boolean>(false);
  const [isScrolledToBottomInstructions, setIsScrolledToBottomInstructions] = useState(false);
  const dialogContentRef = useRef<HTMLDivElement>(null);

  const handleOpenScoringExplanation = () => {
    setShowScoringExplanationDialog(true);
  };

  const handleCloseScoringExplanation = () => {
    setShowScoringExplanationDialog(false);
  };

  const handleScrollInstructions = () => {
    if (dialogContentRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = dialogContentRef.current;
      const isBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;
      setIsScrolledToBottomInstructions(isBottom);
    }
  };

  const getGameCompleteTitleFromScore = (score: number): string => {
    switch (true) {
      case (score > 999):
        return "Perfect score - A true Splatterpiece!"
      case (score > 989):
        return "Splatmaster!"
      case (score > 899):
        return "Holy Splatteroni!"
      case (score > 799):
        return "Splat-talicious!"
      case (score > 749):
        return "Splat-tacular!"
      case (score > 699):
        return "Splat-tastic!"
      case (score > 649):
        return "Splendid Splat-itude!"
      case (score > 549):
        return "Splat's the Way to Do It!"
      case (score > 499):
        return "Great Splatting!"
      case (score > 299):
        return "Good Splattempt..."
      case (score > 149):
        return "Splargh..."
      default:
        return "What a Splattastrophe..."

    }
  }

  const formatGameResults = () => {
    let result = `${gameCompleteTitle}\n`;
    result += `${readableDate}\n`;
    result += `${readableRef}\n`;
    result += `Score: ${gamePlayScore}\n\n`;
    [...turns].reverse().forEach((turn, index) => {
      // result += `${turns.length - index}. `;
      result += `${getEmoji('red', turn.red_bucket_delta, turns.length - index)}`;
      result += `${getEmoji('yellow', turn.yellow_bucket_delta, turns.length - index)}`;
      result += `${getEmoji('blue', turn.blue_bucket_delta, turns.length - index)}\n`;
    });
    result += "https://splattergames.fun"
    return result;
  };

  const handleCopyResults = () => {
    const textToCopy = formatGameResults();
    navigator.clipboard.writeText(textToCopy).then(() => {
      console.log("copy successful")
    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  };
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));

  const FunkySpanDialog = styled('span')(({ theme: _ }) => ({
    fontFamily: "'Bangers', cursive",
    color: targetColor,
    textShadow: `
    -1px -1px 0 #000,  
     1px -1px 0 #000,
    -1px  1px 0 #000,
     1px  1px 0 #000,
     2px 2px 4px rgba(0,0,0,0.3)
  `,
  letterSpacing: isXs ? '1px' : '2px',
    fontSize: isXs ? '1.2rem' : '1.5rem',
  }));

  const FunkySpanMain = styled('span')(({ theme: _ }) => ({
    fontFamily: "'Bangers', cursive",
    color: targetColor,
    textShadow: `
    -1px -1px 0 #000,  
     1px -1px 0 #000,
    -1px  1px 0 #000,
     1px  1px 0 #000,
     2px 2px 4px rgba(0,0,0,0.3)
  `,
  letterSpacing: isXs ? '2px' : '3px',
    fontSize: isXs ? '1.2rem' : '1.5rem',
  }));


  const SplatterSpan = styled('span')(({ theme }) => ({
    fontFamily: "'Bangers', cursive", 
    color: PRIMARY_MAIN, 
    textShadow: '1px 1px 2px rgba(0,0,0,0.1)',
    letterSpacing: '0.5px',
    fontSize: isXs ? '1.2rem' : '1.5rem',
  }));

  const LogoWrapper = styled('span')({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '10px',
  });

  const FunkyButton = styled(Button)(({ theme }) => ({
    borderRadius: '20px',
    fontFamily: "'Bangers', cursive",
    fontSize: isXs ? '1rem' : '1.2rem',
    padding: '8px 16px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.3s ease',
    '&:hover': {
      transform: 'translateY(-2px)',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.15)',
    },
  }));
  
  const ColorCircle = styled(Box)(({ theme }) => ({
    width: isXs ? 50 : 70, 
    height: isXs ? 50 : 70, 
    borderRadius: '50%', 
    border: '2px solid white',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.3s ease',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  }));

  useEffect(() => {
    splatterFetch("/game", {})
      .then(res => res.json())
      .then(game_data => {
        setTargetColor(game_data.metadata.color);
        setIsLoading(false);
        setReadableDate(formatDate(game_data.created_at));
        setReadableRef(game_data.ref);
        setShowInstructionsPopup(!game_data.existing_user)
        setCorrectSliderValues({
          red_percentage: game_data.metadata?.color_percentages?.red_percentage,
          yellow_percentage: game_data.metadata?.color_percentages?.yellow_percentage,
          blue_percentage: game_data.metadata?.color_percentages?.blue_percentage,
        })
        if (!gamePlayId) {
          const body = JSON.stringify({"game_id": game_data.id});
          splatterFetch("/game_play", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body,
          })
            .then(res => res.json())
            .then(gamePlayData => {
              if (gamePlayData.submitted) {
                setGamePlayScore(gamePlayData.metadata.score);
                setGameCompleteTitle(getGameCompleteTitleFromScore(gamePlayData.metadata.score));
                setShowEndGamePopup(true);
              }
              setGamePlayId(gamePlayData.id);
              const turnsData = gamePlayData["turns"];
              if (turnsData.length > 0) {
                console.log("turns")
                console.log(turnsData)
                const lastTurnMetadata = turnsData[turnsData.length - 1]["metadata"]
                setLastUserColor(lastTurnMetadata["color"]);
                setTurns(turnsData.map((turn: any) => turn.metadata));

                const {red_percentage, yellow_percentage, blue_percentage} = lastTurnMetadata
                setSliderValues({
                  red_percentage,
                  yellow_percentage,
                  blue_percentage
                })
                updateCorrectColors(lastTurnMetadata);
              }
            });
        }
      });
  }, []);

  const updateCorrectColors = (turn: Turn) => {
    setCorrectColors({
      red: turn.red_bucket_delta === 0,
      yellow: turn.yellow_bucket_delta === 0,
      blue: turn.blue_bucket_delta === 0,
    });
  };

  const handleSliderChange = (name: keyof SliderValues, value: number) => {
    setSliderValues(prevValues => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    const body = JSON.stringify({
      "metadata": sliderValues,
      "game_play_id": gamePlayId
    });
    splatterFetch('/game_play_turn', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body,
    }).then(res => res.json())
    .then(data => {
      const newTurns = data["turns"];
      if (newTurns.length > 0) {
        const lastTurn = newTurns[newTurns.length - 1]["metadata"];
        setLastUserColor(lastTurn["color"]);
        setTurns(newTurns.map((turn: any) => turn.metadata));
        updateCorrectColors(lastTurn);
      }

      const score = data?.metadata?.score;
      if (score !== undefined) {
        setGamePlayScore(score);
        setGameCompleteTitle(getGameCompleteTitleFromScore(score));
        setShowEndGamePopup(true);
      } else {
        setMessage('Turn submitted. Check the hint below.');
      }
    });
  };

  const handleCloseInstructions = () => {
    setShowInstructionsPopup(false);
  };

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);

    const formatter = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  
    const parts = formatter.formatToParts(date);
    const month = parts.find(part => part.type === 'month')?.value;
    const day = parts.find(part => part.type === 'day')?.value;
    const year = parts.find(part => part.type === 'year')?.value;
  
    return `${month} ${day} ${year}`;
  }

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="sm" sx={{ height: '100vh', display: 'flex', flexDirection: 'column', py: 0.5 }}>
        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Box sx={{ position: 'absolute', top: 4, right: 4 }}>
              <IconButton
                onClick={() => setShowInstructionsPopup(true)}
                size="small"
                sx={{
                  border: '1px solid',
                  borderColor: 'primary.main',
                  '&:hover': {
                    backgroundColor: 'primary.light',
                  },
                }}
              >
                <HelpOutlineIcon fontSize="small" />
              </IconButton>
            </Box>
            <Typography variant={isXs ? "h4" : "h3"} component="h1" gutterBottom align="center" sx={{ 
              fontFamily: "'Bangers', cursive", 
              color: 'primary.main', 
              textShadow: '2px 2px 4px rgba(0,0,0,0.1)', 
              mb: 0.5,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              <img 
                src="/splatter-icon-mr-splatter-2.jpg" 
                alt="Splatter logo" 
                style={{ 
                  width: isXs ? '30px' : '40px', 
                  height: 'auto', 
                  marginRight: '10px'
                }} 
              />
              Splatter!
            </Typography>
            {(readableDate) && 
              <Typography variant="body2" align="center" sx={{ mb: 0 }}>
                {readableDate} - Today's color is:
              </Typography>
            }
            {(readableRef) && 
              <Typography variant={isXs ? "h6" : "h5"} align="center" sx={{ mb: 0.5 }}>
                <FunkySpanMain>{readableRef}</FunkySpanMain>
              </Typography>
            }
            <Grid container spacing={1} justifyContent="center" sx={{ mb: 0.5 }}>
              <Grid item xs={6}>
                <Paper elevation={3} variant="outlined" sx={{ p: 0.5, textAlign: 'center', border: 'none' }}>
                  <Typography variant="body2">Target Color</Typography>
                  <ColorCircle sx={{ backgroundColor: targetColor, margin: 'auto' }} />
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper elevation={3} variant="outlined" sx={{ p: 0.5, textAlign: 'center', border: 'none' }}>
                  <Typography variant="body2">Your Last Guess</Typography>
                  <ColorCircle sx={{ backgroundColor: lastUserColor, margin: 'auto' }} />
                </Paper>
              </Grid>
            </Grid>
            <Paper elevation={3} sx={{ p: 1, mb: 0.5, flexGrow: 0, display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ mb: 0.5 }}>
                <ColorSlider 
                  color="red"
                  value={sliderValues.red_percentage}
                  onChange={(value) => handleSliderChange('red_percentage', value)}
                  disabled={gamePlayScore !== undefined}
                  isCorrect={correctColors.red}
                />
              </Box>
              <Box sx={{ mb: 0.5 }}>
                <ColorSlider 
                  color="yellow"
                  value={sliderValues.yellow_percentage}
                  onChange={(value) => handleSliderChange('yellow_percentage', value)}
                  disabled={gamePlayScore !== undefined}
                  isCorrect={correctColors.yellow}
                />
              </Box>
              <Box sx={{ mb: 0.5 }}>
                <ColorSlider 
                  color="blue"
                  value={sliderValues.blue_percentage}
                  onChange={(value) => handleSliderChange('blue_percentage', value)}
                  disabled={gamePlayScore !== undefined}
                  isCorrect={correctColors.blue}
                />
              </Box>
              <FunkyButton 
                variant="contained" 
                color="primary" 
                onClick={handleSubmit} 
                disabled={gamePlayScore !== undefined}
                fullWidth
                size="small"
              >
                Splatter!
              </FunkyButton>
            </Paper>
            {message && (
              <Alert severity={gamePlayScore !== undefined ? "success" : "info"} sx={{ mb: 0.5, borderRadius: '10px', py: 0 }}>
                <Typography variant="body2">{message}</Typography>
              </Alert>
            )}
            <Paper elevation={3} sx={{ p: 1, flexGrow: 0 }}>
              <TurnList turns={[...turns].reverse()} />
            </Paper>
          </Box>
        )}
  
      <Dialog
        open={showInstructionsPopup}
        onClose={handleCloseInstructions}
        aria-labelledby="instructions-dialog-title"
      >
        <DialogTitle id="instructions-dialog-title">How to Play <SplatterSpan>Splatter</SplatterSpan></DialogTitle>
        <DialogContent ref={dialogContentRef} onScroll={handleScrollInstructions}>
          <Typography paragraph>
            Welcome to Splatter! Here's how to play:
          </Typography>
          <Typography paragraph>
            1. Your goal is to match the target color using the red, yellow, and blue sliders.
          </Typography>
          <Typography paragraph>
            2. Adjust the sliders to mix the colors and click the <SplatterSpan>Splatter!</SplatterSpan> button to submit your guess.
          </Typography>
          <Typography paragraph sx={{ paddingLeft: '20px' }}>
            Pro Tip - Use the -/+ buttons for smaller adjustments!<br/>
          </Typography>
          <Typography paragraph>
            3. The turn history will show you how close you are:
          </Typography>
          <Typography paragraph sx={{ paddingLeft: '20px' }}>
            🟥, 🟨 or 🟦 means you've matched that color.<br />
            🔴, 🟡 or 🔵 means you're almost there.<br/>
            ⬜ means you're still far off.<br />
          </Typography>
          <Typography paragraph>
            If you haven't matched by the last few turns, you will get more hints if you're still far off:
          </Typography>
          <Typography paragraph sx={{ paddingLeft: '20px' }}>
            ⬆️ means you need more of that color<br/>
            ⬇️ means you need less of that color.<br/>
          </Typography>
          <Typography paragraph>
            4. Keep adjusting and guessing until you match the target color or you hit the 5 turn limit. Happy Splatting!
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleCloseInstructions} 
            color="primary"
            disabled={!isScrolledToBottomInstructions}
          >
            Let's Splat!
          </Button>
        </DialogActions>
      </Dialog>
  
<Dialog
        open={showEndGamePopup}
        aria-labelledby="end-game-dialog-title"
      >
          <DialogTitle id="end-game-dialog-title" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <LogoWrapper>
              <img 
                src="/splatter-icon-mr-splatter-2.jpg" 
                alt="Splatter logo" 
                style={{ 
                  width: isXs ? '24px' : '30px', 
                  height: 'auto',
                }}
              />
            </LogoWrapper>
            <SplatterSpan>{gameCompleteTitle}</SplatterSpan>
        </DialogTitle>
        <DialogContent>
          <Typography variant="subtitle2" gutterBottom>
            {readableDate}
          </Typography>
          <Typography variant="h6" gutterBottom>
            <FunkySpanDialog>{readableRef}</FunkySpanDialog>
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <Typography variant="subtitle1" sx={{ mr: 1 }}>
              Score: {gamePlayScore}
            </Typography>
            <IconButton
              size="small"
              onClick={handleOpenScoringExplanation}
              aria-label="Scoring explanation"
            >
              <InfoOutlinedIcon fontSize="small" />
            </IconButton>
          </Box>
          <Box sx={{ mt: 1 }}>
            {[...turns].reverse().map((turn, index) => (
              <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                <Typography variant="body2" sx={{ width: '20%' }}>
                  {turns.length - index}
                </Typography>
                <Typography variant="body2" sx={{ width: '80%', display: 'flex', justifyContent: 'space-around' }}>
                  <span>{getEmoji('red', turn.red_bucket_delta, turns.length - index)}</span>
                  <span>{getEmoji('yellow', turn.yellow_bucket_delta, turns.length - index)}</span>
                  <span>{getEmoji('blue', turn.blue_bucket_delta, turns.length - index)}</span>
                </Typography>
              </Box>
            ))}
          </Box>
          <ComparisonColorSlider
            color="red"
            userValue={sliderValues.red_percentage}
            correctValue={correctSliderValues.red_percentage}
          />
          <ComparisonColorSlider
            color="yellow"
            userValue={sliderValues.yellow_percentage}
            correctValue={correctSliderValues.yellow_percentage}
          />
          <ComparisonColorSlider
            color="blue"
            userValue={sliderValues.blue_percentage}
            correctValue={correctSliderValues.blue_percentage}
          />
          <Typography variant="subtitle2" gutterBottom>
           Come back tomorrow for the next Splat!
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCopyResults}
            color="primary"
            startIcon={<ContentCopyIcon />}
          >
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showScoringExplanationDialog}
        onClose={handleCloseScoringExplanation}
        aria-labelledby="scoring-explanation-dialog-title"
      >
        <DialogTitle id="scoring-explanation-dialog-title">How Scoring Works</DialogTitle>
        <DialogContent>
          <Typography variant="body2" paragraph>
            The scoring system in Splatter is based on the number of turns it takes and how far off each guess is:
          </Typography>
          <Typography variant="body2" paragraph>
            1. Start with a perfect 1000 points.<br/>
            2. Points are deducted based on how close your guesses were in each turn.<br/>
            3. If you don't get matched by the end of the five turns, your score is cut in half.<br/>
          </Typography>
          <Typography variant="body2" paragraph>
            Some Notes:<br/>    
            - Even if it's a match, your guess can be further or closer from the exact target color, which may be why there are points lost.<br/>
            - Consistent accuracy across all turns helps maintain a good score.<br/>
            - The less turns used, the better!
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseScoringExplanation} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
    </ThemeProvider>
  );
}

export default App;